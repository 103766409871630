@import "react-toastify/dist/ReactToastify.css";

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
  --blue-400: #2186be;
}

.error-rounded-icon {
  margin-right: 0.5rem;
  color: #212121cc;
}

.mantine-1qr48zw {
  background-color: var(--theme-sidebar-nonCompleted);
}

.mantine-36cczg {
  border: 1px dashed #998000;
}
.mantine-q7xcdy {
  border: 1px dashed #f1f3f5;
}
.mantine-1pvoioz {
  margin-top: 12px;
}
.mantine-Dropzone-root {
  border: 1.5px dashed #ced4da;
}
::placeholder {
  font-family: var(--font-chivo);
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.w-one-third {
  width: 33.333333%;
}
a {
  color: #2186be;
  font-weight: 400;
}

select,
select option {
  color: #000000;
}

select:invalid,
select option[value=""] {
  color: #999999;
}

/*Added for browser compatibility*/
[hidden] {
  display: none;
}

@media screen and (max-width: 1280px) {
  ::placeholder {
    font-size: 14px;
  }
}
.mantine-itndq9[data-active] {
  background-color: #ffffff;
}

.no-top-border {
  border-top: none !important;
}

/* Attempt to fix datepicker buttons not working intermittently */
/* I think one of the elements behind them (an AppButton maybe) is intercepting the click */
button[class^="mantine-DatePicker-"] {
  z-index: 9999 !important;
}

.column-container {
  column-count: 2;
  column-gap: 30px;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-top-border {
  border-top: none;
}

@media screen and (min-width: 1500px) {
  .css-1ie20hd-indicatorContainer,
  .css-1dmivbd-indicatorContainer {
    margin-bottom: 35px;
  }
}

/*
These styles are for Ag grid
 */
.ag-theme-quartz .ag-header {
  --ag-header-background-color: rgb(231, 237, 244);
}
.ag-theme-quartz .ag-header {
  font-family: var(--font-chivo);
}

.ag-theme-quartz .ag-header-cell {
  font-size: 20px;
  font-weight: bold;
}

.ag-row .ag-cell {
  font-family: var(--font-chivo);
  display: flex;
  align-items: center;
  font-size: 17px;
}

tbody tr td:first-child {
  padding-left: 20px;
}

thead tr th:first-child {
  padding-left: 20px;
}

.ag-cell-value {
  padding-left: 0;
  padding-right: 0;
}

/* Add this to your stylesheet */
.ag-cell-focus.ag-cell-inline-editing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 2px;
}

.shiny-button {
  position: relative;
  overflow: hidden;
}

.shiny-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-45deg);
  animation: shine 61s infinite;
}

@keyframes shine {
  0% {
    left: -150%;
  }
  3% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}

@keyframes bounce-custom {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.bounce-custom {
  animation: bounce-custom 1s infinite;
}

.mdx-editor {
  position: relative;
}

.mdx-editor [role="listbox"],
.mdx-editor [role="menu"],
.mdx-editor [role="dialog"],
.mdx-editor .dropdown-wrapper {
  z-index: 50;
  position: relative;
}

div[data-radix-popper-content-wrapper] {
  z-index: 100 !important;
}

.mdx-editor [role="listbox"] {
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-top: 0.25rem;
}

.mdx-editor [role="option"] {
  padding: 0.5rem;
  cursor: pointer;
}

.mdx-editor [role="option"]:hover {
  background-color: #f3f4f6;
}

.mdx-editor .dialog-wrapper {
  z-index: 1000;
  position: relative;
}

.mdx-editor-wrapper {
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  overflow: visible;
}

.mdx-editor-wrapper .toolbar-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
  background-color: #f8fafc;
}

.mdx-editor-wrapper .divider {
  width: 1px;
  height: 24px;
  background-color: #e2e8f0;
  margin: 0 0.25rem;
}

.mdx-editor {
  background-color: white;
  min-height: 300px;
  padding: 1rem;
}

.mdx-editor:focus-within {
  outline: 2px solid #3b82f6;
  outline-offset: -2px;
}

.mdx-editor button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem;
  border-radius: 0.25rem;
  background: transparent;
  color: #4b5563;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s;
}

.mdxeditor-popup-container {
  z-index: 1000 !important;
}

.mdx-editor button:hover {
  background-color: #f3f4f6;
  color: #1f2937;
}

.mdx-editor button[data-active="true"] {
  background-color: #e5e7eb;
  color: #1f2937;
}

.mdx-editor select {
  padding: 0.375rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  background-color: white;
  color: #4b5563;
  cursor: pointer;
  font-size: 0.875rem;
}

.mdx-editor select:hover {
  border-color: #cbd5e1;
}

.mdx-editor table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
}

.mdx-editor th,
.mdx-editor td {
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
}

.mdx-editor th {
  background-color: #f8fafc;
}

/* List styles */
.mdx-editor ul,
.mdx-editor ol {
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}

.mdx-editor h1,
.mdx-editor h2,
.mdx-editor h3,
.mdx-editor h4,
.mdx-editor h5,
.mdx-editor h6 {
  margin: 1rem 0 0.5rem;
  font-weight: 600;
  line-height: 1.25;
}
